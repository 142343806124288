<template>
    <div class="con-wrap">
        <CarrotTitle title="공지사항"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div>
                        <table class="table-row table-serach">
                            <colgroup>
                                <col width="180">
                                <col width="*">
                                <col width="180">
                                <col width="*">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>기간</th>
                                    <td colspan="3">
                                        <carrot-date-picker v-model="bbs.sdate" class="w-110px dp-inblock mr-10"></carrot-date-picker>
                                        ~
                                        <carrot-date-picker v-model="bbs.edate" class="w-110px dp-inblock ml-10"></carrot-date-picker>
                                    </td>
                                </tr>
                                <tr>
                                    <th>제목</th>
                                    <td>
                                        <input type="text" class="w-100per" v-model="bbs.title">
                                    </td>
                                    <th>작성자</th>
                                    <td>
                                        <input type="text" class="w-100per" v-model="bbs.writer">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button class="btn-default float-right mt-10" @click="bbs.getList()">검색</button>
                        <div class="clear"></div>
                    </div>

                    <div class="mt-50 mb-20">
                        <button class="btn-default float-right" @click="bbs.showAdd()">새글 작성하기</button>
                        <div class="clear"></div>
                    </div>
                    
                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="670">
                            <col width="150">
                            <col width="150">
                            <col width="150">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>제목</th>
                                <th>작성자</th>
                                <th>작성일</th>
                                <th>조회수</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.topfix=='Y'?'-':irow.num }}</td>
                                <td class="txt-left" @click="bbs.showView(irow.idx)">
                                    <span class="btn-view">{{ irow.title }}</span>
                                    <span class="list-num ml-5" v-if="irow.cnt_cmt>0">({{ irow.cnt_cmt }})</span>
                                    <span class="ico-n ml-5" v-if="irow.is_new=='Y'">NEW</span>
                                </td>
                                <td class="txt-ellipsis">{{ irow.ename }}({{ irow.kname }})</td>
                                <td>{{ irow.wdate }}</td>
                                <td>{{ irow.hit }}</td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="5">등록된 공지사항이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.getList()"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'


export default {
    layout:"CARROTZone",
    components: {
        CarrotDatePicker
    },
    setup() {
        const router = useRouter()
        const toast  = useToast()

        const bbs = reactive({
            isMounted : false,

            topfix: false,
            page : 1,
            rows : 50,
            sdate: "",
            edate: "",
            title: "",
            writer: "",
            list : [], total : 0,
            getList: () => {
                if( bbs.isMounted == false ) return;

                let params = {
                    page  : bbs.page,
                    sdate : bbs.sdate,
                    edate : bbs.edate,
                    title : bbs.title,
                    writer: bbs.writer,
                    rows : bbs.rows,
                }
                axios.get('/rest/carrotzone/noticeList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        // console.info(res.data.err_msg);
                        if( res.data.err_msg ) toast.error(res.data.err_msg);
                    }
                })
            },
            showView: (idx)=>{
                // location.href = "NoticeView/" + idx;
                router.push({
                    name   : 'CARROTZone-NoticeView-idx',
                    params : { idx:idx }
                });
            },
            showAdd: () => {
                // location.href = "NoticeAdd";
                router.push('NoticeAdd');
            }
        });
        
        onMounted(() => {
            // Mounted
            bbs.isMounted = true;

            bbs.getList();
        });

        onUnmounted(() => { 
            // UnMounted
        });
        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>